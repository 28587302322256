import React from 'react';
import Helmet from 'react-helmet';
import { Container, Layout } from 'components';
import { FaInstagram, FaEnvelope, FaGithub, FaLinkedin} from "react-icons/fa";
import styled from 'react-emotion';
import config from '../../config/website';

// 2c3e50

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display:flex;
  justify-content:center;
  align-items: center;
  text-align: center;
  color: #011627;
`;

const IconContainer = styled.div`
  display:flex;
  margin-top: 2em;
  justify-content: center;
  a {
  color: #011627 !important;
    &:hover {
      color:#00F0B5 !important;
      transition:  ease-in-out 0.2s;
    };
  }
  svg {
    font-size:2em;
    margin-right: 1em;
  }
`;

const Contact = () => (
  <Layout>
    <Helmet title={`Contact | ${config.siteTitle}`} />
    <Wrapper>
      <Container type="text">
        <h2>Contact me!</h2>
        <p>Apart from here, you can also <br/> find me on other platforms:</p>
        <IconContainer>
          <a href="mailto:hello@aidenkwok.co.uk?&amp;subject=hello!"><FaEnvelope/></a>
          <a href="https://www.instagram.com/aidenese/"><FaInstagram /></a>
          <a href="https://github.com/aidenkwok"><FaGithub /></a>
          <a href="https://www.linkedin.com/in/aidenkwok"><FaLinkedin /></a>
        </IconContainer>
      </Container>
    </Wrapper>
  </Layout>
);

export default Contact;
